// author锛歫iana
// time:2024-06-12
// desc:鏈〉闈负鏂逛究蹇€熸瀯寤簐ue鏂囦欢妯℃澘鍩虹椤甸潰鎼缓锛屽湪娆″熀纭€涓婂揩閫熸惌寤篭r

<template>
	<div class="socialResponsibility">
		<menuNav :bgClass="true"></menuNav>
		<div class="topImg">
			<el-carousel height="7.2rem" arrow='never' indicator-position="none">
				<el-carousel-item v-for="item,index in imageList" :key="index">
					<img :src="item.url" alt=""  @click="goDetail(item)">
					<div class="imgName">{{item.name}}</div>
				</el-carousel-item>
			</el-carousel>
		</div>
		<div class="box1200">
			<div class="marginTop50" v-for="item,index in tableData.data" :key="index" @click="goInfo(item,index)">
				<el-row :gutter="20">
					<el-col :span="10">
						<img :src="item.url" alt="" class="imgBox"/>
					</el-col>
					<el-col :span="12">
						<div class="jsTitle">{{item.title}}</div>
						<div class="jsInfo" v-html="item.content"></div>
						<div class="time">{{item.createTime | dateFormate}}</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<div class="fenye">
			<el-pagination background small layout="prev, pager, next" :total="tableData.total" :page-size="searchFilter.limit" @current-change="handleCurrentChange"></el-pagination>
		</div>
		<!-- 渚ц竟瀵艰埅 -->
		<div class="rightBox">
			<rightNav @goBack="goBack"></rightNav>
		</div>
		<!-- 搴曢儴 -->
		<footerIndex></footerIndex>
	</div>
</template>

<script>
import menuNav  from '../../components/nav/menuNav.vue'
import rightNav  from '../../components/nav/rightNav.vue'
import footerIndex from '../../components/footer/footerIndex.vue'
import {resourceList,resourcePage} from './../../request/service'
export default {
	name: 'socialResponsibility',
	components: {
		menuNav,footerIndex,rightNav
	},
	props:{
		
	},
	data() {
		return {
			clickHonorIndex:1,
			searchFilter:{
				limit:10,
				offset:0,
				typeCode:'gywm_002_002'
			},
			tableData:{
				data:[],
				total:0
			},
			imageList:[],
		}
	},
	computed: {

	},
	methods: {
		clickHonor(val){
			this.clickHonorIndex = val
		},
		//鍒嗛〉-淇敼姣忛〉鍛堢幇鐨勬暟鎹噺
		handleCurrentChange(val) {
			console.log(`褰撳墠椤代 ${val}`);
			this.searchFilter.offset = this.searchFilter.limit * (val-1)
			this.getNews()
		},
		//鍥炲埌椤堕儴
		goBack(){
			window.scrollTo(0,0)
		},
		//椤跺浘
		getImage(){
			resourceList({typeCode:'gywm_002_001'}).then((res) => {
				if(res.code == 101){
					this.imageList = res.data
				}else{
					this.$Message.error(res.message);
				}
			})
		},
		goDetail(item){
			if(item.link != ''){
				if(item.link.indexOf('www.aiyangniu.net') !== -1 || item.link.indexOf('www.aiyangniu.cn') !== -1){      //璺宠浆鍐呴儴椤甸潰
					this.$jump({path:link.split("#")[1]})
				}else{      //璺宠浆澶栭儴閾炬帴
					window.open(item.link)
				}
			}
		},
		getNews(){
			resourcePage(this.searchFilter).then((res) => {
				if(res.code == 101){
					this.tableData = res
				}else{
					this.$Message.error(res.message);
				}
			})
		},
		goInfo(item){
			this.$jump('/InfoDetail?id='+item.id)
		}
	},

	mounted() {
		this.getImage()
		this.getNews()
	},

	watch: {

	}
}
</script>

<style lang="less" scoped>
.socialResponsibility{
	.topImg{
		width: 100%;
		height: 270px;
		margin-top: 50px;
		img{
			width: 100%;
			height: 100%;
		}
	}
	.box1200{
		width: 1200PX;
		margin: 0 auto;
	}
	.marginTop50{
		margin-top: 50px;
		cursor: pointer;
	}
	.imgBox{
		width: 300px;
		height: 200px;
	}
	.jsTitle{
		color: #2B2C2E;
		font-size: 12px;
		margin-top: 20px;
		width: 100%;
		height: 25px;
		overflow:hidden;
		width-space:nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
	.imgName{
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 36px;
		font-family: '闃块噷濡堝鏁伴粦浣?;
		color: #fff;
	}
	.jsInfo{
		color: #2B2C2E;
		margin-top: 20px;
		line-height: 20px;
		font-size: 10px;
		height: 80px;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 4; /* 鏄剧ず鐨勮鏁帮紝鍙互鏍规嵁闇€瑕佷慨鏀 */
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.time{
		margin-top: 20px;
		color: #9C9FA5;
		font-size: 10px;
	}
	.fenye{
		text-align: center;
    	margin: 30px 0;
	}
	.rightBox{
		position: fixed;
		right: 10px;
		top: 50vh;
		z-index: 9999;
	}
}
</style>
<style lang="less">
.socialResponsibility{
	.el-pagination.is-background.el-pagination--small .el-pager li{
		border-radius: 0.3rem;
	}
	.el-pagination.is-background.el-pagination--small .btn-prev{
		border-radius: 0.3rem;
		color: #9C9FA5;
		background-color:#fff;
		border: 1px solid #eee;
	}
	.el-pagination.is-background.el-pagination--small .btn-next{
		border-radius: 0.3rem;
		color: #9C9FA5;
		background-color:#fff;
		border: 1px solid #eee;
	}
}
</style>